#popup {
  position: absolute;
  display: none;
  top: 20%;
  left: calc(50% - 500px / 2);
  background-color: #7a838b;
  background-color: var(--background-color-dialog);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border: 1px solid var(--border-color);
  width: 500px;
  max-width: 100%;
  max-height: calc(80%);
  margin: 0px auto 0px auto;
  padding: 16px 24px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.6);
  z-index: 200;
  overflow-y: auto;
  font-size: 16px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  /* overflow-y: hidden; Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
#popup.wide {
  top: 15%;
  width: 800px;
  left: calc(50% - 800px / 2);
}
#popup a {
  color: var(--text-color);
}
#popup h2 {
  margin: -10px -10px 5px -10px;
  padding: 6px 10px;
  font-size: 1.3em;
  background-color: rgba(255, 255, 255, 0.3);
  background-color: var(--background-color-header);
  /*cursor:move;*/
}
#popup .buttons {
  text-align: right;
  margin-top: 20px;
  margin-bottom: 5px;
  outline: none;
}
#popup .close {
  float: right;
  min-width: 0;
  padding: 5px;
  line-height: 0.5;
  font-size: 1.3em;
  margin-left: 10px;
  border: none;
  background: none;
  color: var(--text-color);
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 300;
  outline: none;
}
#popup td,
#popup th {
  height: 25px;
}
#popup td {
  vertical-align: middle;
}
#popup th {
  text-align: left;
  padding: 5px 5px 5px 0;
  width: 130px;
}
#popup textarea {
  color: var(--text-color);
  background-color: var(--background-color-button);
  width: 100%;
  border: 1px solid #393939;
  padding-left: 5px;
}
#popup .button {
  margin: 0 3px;
  background-color: rgba(0, 0, 0, 0);
  color: var(--text-color);
  min-width: 60px;
  border: 0px solid rgba(0, 0, 0, 0.5);
  border: 0px solid var(--border-color);
  border-radius: 4px;
  padding: 5px 10px;
  text-transform: uppercase;
  outline: none;
  font-weight: 500;
}
#popup input[type='text'],
#popup input[type='number'],
#popup textarea {
  width: 100%;
  outline: none;
}
#popup input[type='number'] {
  width: 100px;
}
#popup input[type='radio'],
#popup input[type='checkbox'] {
  margin-left: 0;
  outline: none;
}
#popup label span {
  color: #444444;
}
#popup .checkbox label {
  margin-top: 5px;
  color: #444444;
}
@media screen and (max-width: 500px) {
  #popup {
    left: 0;
    width: 100%;
  }
  #canvas_preview_container {
    clear: both;
  }
}
@media screen and (max-height: 650px) {
  #popup {
    top: 0;
    max-height: 100%;
  }
  #canvas_preview_container {
    clear: both;
  }
}
