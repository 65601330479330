.wrapper {
  height: 100%;
  margin-top: 0px;
  position: relative;
  overflow: hidden;
}
.trn {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  /* font: 16px Roboto, Arial, sans-serif; */
}
.toggle {
  cursor: pointer;
}
.hidden {
  display: none;
}
.pointer {
  cursor: pointer;
}
.clear {
  clear: both;
}
.displayBlock {
  display: block;
}
.bold {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 700;
}
.normal {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 300;
}
.light {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 200;
}
.thin {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 100;
}
.left {
  float: left;
}
.right {
  float: right;
}
.grey {
  color: grey;
}
.external {
  padding-right: 15px;
}
.external:after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  margin-left: 5px;
  background: url(images/sprites.png) no-repeat -700px 0;
  opacity: 0.3;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version */
}
.block {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  background-color: var(--background-color-area);
  border-radius: 10px;
  margin-bottom: 10px;
  user-select: none;
}

.block:last-child {
  margin-bottom: 0;
}
.block h2 {
  position: relative;
  padding-left: 8px;
  padding-top: 3px;
  margin: 0;
  font-size: 1.3em;
}
.block.toggled h2,
.block h2.toggled:after {
  border: none;
}
.block h2.toggle:before {
  /* icon */
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  right: 8px;
  top: 12px;
  border-style: solid;
  border-width: 0 6px 8px 6px;
  border-color: transparent transparent #d5d5d5 transparent;
}
.block h2.toggled:before {
  /* icon */
  border-width: 8px 6px 0 6px;
  border-color: #d5d5d5 transparent transparent transparent;
}
.block .content {
  padding: 5px;
}
.error {
  padding: 20px;
  margin: 10px;
  border: 1px solid #ff0000;
  background-color: #ffffff;
  width: 500px;
  font-weight: bold;
}

/* color chooser */
body .sp-replacer {
  width: 100%;
  height: 40px;
}
body .sp-preview {
  width: calc(100% - 20px);
  height: 100%;
}

/* ========== header ======================================================== */

.logo {
  position: relative;
  display: inline-block;
  float: left;
  height: 30px;
  width: 75px;
  padding: 5px 5px 5px 36px;
  margin: 5px;
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  color: #ffffff;
  color: var(--text-color);
}
.logo:after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 31px;
  height: 30px;
  /* background: url(images/sprites.png) -50px -100px no-repeat; */
}
.about-logo {
  margin-left: 22%;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97);
}
.about-name {
  font-size: 15px;
  color: #650eb2;
  font-weight: 700;
}
@keyframes shake {
  10%,
  90% {
    transform: translate(2px, 0px);
  }
  20%,
  80% {
    transform: translate(5px 0px);
  }
  30%,
  50%,
  70% {
    transform: translate(0px 0px);
  }
  40%,
  60% {
    transform: translate(6px 0px);
  }
}
/* ========== sub-header ==================================================== */

.submenu {
  height: 40px;
  background-color: rgba(255, 255, 255, 0);
  /* background-color: var(--background-color-section); */
  overflow: hidden;
}
.attributes {
  float: left;
  width: 100%;
  height: 30px;
  margin-top: 5px;
  padding: 3px 10px 0 10px;
  border: 0;
}
.attributes .item {
  display: inline-block;
  margin-right: 12px;
}
.attributes input[type='number'] {
  width: 60px;
  margin-right: 5px;
  color: var(--text-color);
  background-color: var(--background-color-input);
}
.attributes button {
  display: inline-block;
  padding: 3px 10px;
  border: 1px solid #444;
  border-color: var(--border-color);
  border-radius: 4px;
  color: var(--text-color);
  background-color: var(--background-color-button);
  margin-right: 5px;
  outline: none;
  text-transform: uppercase;
  font-weight: 500;
}
.attributes button:hover {
  background-color: rgba(255, 255, 255, 0.3);
  background-color: var(--background-color-hover);
}
.attributes button.active {
  background-color: #419147;
  background-color: var(--background-color-active);
}

/* ========== left sidebar ================================================== */

.sidebar_left {
  position: absolute;
  overflow: auto;
  height: 100%;
  left: 0px;
  top: 75px;
  width: 100px;
  padding-left: 4px;
  padding-right: 2px;
  padding-bottom: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.sidebar_left .item {
  display: inline-block;
  border: 1px solid var(--background-color-input-border-active);
  /* border-color: var(--border-color); */
  border-color: var(--background-color-input-border-active);
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 1);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-origin: content-box;
  height: 40px;
  cursor: pointer;
  width: 40px;
  margin-right: 5px;
  padding: 5px;
  margin: 1px 3px 0 3px;
}
.sidebar_left .item:hover {
  background-color: rgba(255, 255, 255, 0.5);
  background-color: var(--background-color-hover);
}
.sidebar_left .item.active {
  background-color: #419147;
  background-color: var(--background-color-active);
}

.sidebar_left .select {
  background-image: url('images/mouse_icon.png');
}
.sidebar_left .selection {
  background-image: url('images/selection_icon.png');
}
.sidebar_left .magic_wand {
  background-image: url('images/magic_erase_icon.png');
}
.sidebar_left .brush {
  background-image: url('images/paint_brush_icon.png');
}
.sidebar_left .erase {
  background-image: url('images/erase_icon.png');
}
.sidebar_left .fill {
  background-image: url('images/paint_bucket_icon.png');
}
.sidebar_left .pick_color {
  background-image: url('images/eye_dropper_icon.png');
}
.sidebar_left .pencil {
  background-image: url('images/pencil_icon.png');
}
.sidebar_left .line {
  background-image: url('images/line_icon.png');
}
.sidebar_left .text {
  background-image: url('images/text_icon.png');
}
.sidebar_left .rectangle {
  background-image: url('images/square_icon.png');
}
.sidebar_left .circle {
  background-image: url('images/circle_icon.png');
}
.sidebar_left .blur {
  background-position: -41px 2px;
}
.sidebar_left .sharpen {
  background-position: 6px -47px;
}
.sidebar_left .desaturate {
  background-position: -195px 3px;
}
.sidebar_left .bulge_pinch {
  background-position: -93px 5px;
}
.sidebar_left .clone {
  background-image: url('images/clone_stamp_icon.png');
}
.sidebar_left .palette256 {
  background-image: url('images/palette_256_icon.png');
}
.sidebar_left .rasterize {
  background-image: url('images/rasterize_icon.png');
}
.sidebar_left .gradient {
  background-position: -243px 5px;
}
.sidebar_left .crop {
  background-position: -294px 3px;
}
.sidebar_left .animation {
  background-position: -244px -47px;
}
.sidebar_left .media {
  background-position: -145px -47px;
}

/* ========== right sidebar ================================================= */

.sidebar_right {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  right: 0px;
  top: 80px;
  width: 200px;
  /* large padding with negative margin needed for absolute position with things in the overflow plus scroll ability, can't see the tooltip texts without it */
  padding-left: 5px;
  height: calc(100% - 80px);
  overflow-y: auto;
  background-color: #424f5a;
  background-color: var(--background-color);
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 10px;
  transition: 0.2s;
}
.sidebar_right.active {
  right: 0px !important;
}
.sidebar_right .block.layers {
  flex: 1;
}

.sidebar_right .block {
  /* large padding with negative margin needed for absolute position with things in the overflow plus scroll ability, can't see the tooltip texts without it */
  margin-left: 0px;
  background-color: #68727b;
  border: 1px solid var(--background-color-hover);
  padding-bottom: 4px;
  background-color: var(--background-color-section);
}

/* preview */
.canvas_preview_wrapper {
  position: relative;
  width: 128px;
  height: 128px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  /* margin: 0px 5px 10px 5px; */
}
.canvas_preview_details {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
}
.preview canvas {
  cursor: pointer;
}
.preview_details {
  display: flex;
  justify-content: space-between;
  align-content: space-between;
}
.preview_details input {
  padding: 8px 10px;
  background-color: var(--background-color-input);
  color: var(--text-color);
  text-transform: uppercase;
  font-weight: 500;
}

.details input {
  padding: 8px 10px;
  background-color: var(--background-color-input);
  color: var(--text-color);
  text-transform: uppercase;
  font-weight: 500;
}

/* color */
.color_area {
  width: calc(100% - 10px);
  cursor: pointer;
  margin-left: 5px;
}
.colors .content {
  margin-top: 10px;
}
.main_color_alt {
  border: 1px solid #393939;
  margin-top: 10px;
  width: 100%;
  height: 40px;
}
.main_color_rgb {
  margin-top: 10px;
}
.main_color_rgb div {
  display: inline-block;
  width: calc(50% - 3px);
  vertical-align: top;
}
.main_color_rgb span {
  display: inline-block;
  margin-left: 3px;
  width: 16px;
}

.hex {
  display: inline-block;
  margin-left: 3px;
  width: 35px;
}
.main_color_rgb input {
  width: 61px;
  color: var(--text-color);
  background-color: var(--background-color-input);
}
.main_color_rgb .red {
  /* color: #aa0000; */
  text-shadow: 0 0 5px #ff0000;
}
.main_color_rgb .green {
  /* color: #00aa00; */
  text-shadow: 0 0 5px #00ff00;
}
.main_color_rgb .blue {
  /* color: #0000aa; */
  text-shadow: 0 0 5px #0000ff;
}
.main_color_rgb .alpha {
  color: var(--text-color);
}

.color_hex {
  width: calc(100% - 50px);
  color: var(--text-color);
  background-color: var(--background-color-input);
}
#all_colors {
  margin-top: 10px;
  padding: 3px 0px 3px 3px;
}

/* layers */
.layers_list {
  margin-top: 10px;
  padding-left: 3px;
  padding-right: 2px;
}
.layers_arrow {
  display: inline-block;
  float: right;
  margin-left: 5px;
  padding: 1px 8px;
  border: 1px solid var(--background-color-input-border-active);
  border-color: var(--border-color);
  border-radius: 4px;
  text-decoration: none;
  outline: none;
  background-color: var(--background-color-button);
  color: var(--text-color);
  font-size: 12px;
  font-weight: 500;
}
.layer_add {
  outline: none;
  display: inline-block;
  padding: 1px 8px;
  background-color: #419147;
  /* background-color: var(--background-color-active); */
  background-color: var(--background-color-button);
  border: 1px solid #444;
  border-radius: 4px;
  border-color: var(--border-color);
  color: var(--text-color);
  cursor: pointer;
  text-decoration: none;
}
.layers_list .item {
  margin-bottom: 4px;
  margin-left: -4px;
  margin-right: -4px;
  /* bottom line divider between items */
  border-radius: 3px;
  border-bottom: 1px solid 0.75em transparent;
  box-shadow: 3px 11px 0 -10px var(--border-color);
}
.layers_list .layer_name {
  display: block;
  padding: 0px 3px 0px 2px;
  height: auto;
  overflow: hidden;
  background-color: #6a6a6a;
  background-color: var(--background-color-area);
  cursor: pointer;
  overflow: hidden;
  font-size: 1em;
  color: var(--text-color);

  white-space: nowrap;
}
.layers_list .item.active {
  background-color: var(--background-color-active);
  box-shadow: 3px 11px 0 -10px var(--border-color), inset 0 0 6px 3px #00000000;
}
.layers_list .visibility {
  float: left;
  cursor: pointer;
  padding: 0px 3px 0px 3px;
  margin-right: 5px;
  margin-top: 2px;
  width: 20px;
  height: 19px;
  background: url('images/sprites.png') no-repeat -148px -99px;
  opacity: 0.4;
  filter: drop-shadow(0px 0px 2px var(--text-color));
}
.layers_list .visible {
  opacity: 1;
}
.layers_list .delete {
  float: right;
  cursor: pointer;
  padding: 0px 6px 0px 6px;
  width: 16px;
  height: 21px;
  margin-left: 5px;
  margin-top: 3px;
  background: url('images/sprites.png') no-repeat -100px -96px;
}
/* filters */
.layers_list .filters {
  margin-bottom: 5px;
}
.layers_list .filter {
  margin-bottom: 2px;
  margin-left: 30px;
  opacity: 0.7;
}
.layers_list .filter .layer_name {
  position: relative;
}
.layers_list .filter .layer_name:after {
  position: absolute;
  content: 'fx';
  right: -4px;
  top: 1px;
  bottom: 0;
  width: 20px;
}
.sidebar_right .label {
  display: inline-block;
  width: 60px;
}
.info .toggle.toggled {
  margin-bottom: -3px;
}
.block.details .row {
  clear: both;
  margin-bottom: 2px;
}
.block.details input {
  width: 70px;
  padding: 3px 5px;
  color: var(--text-color);
  background-color: var(--background-color-input);
}
.block.details select {
  width: calc(100% - 70px);
  height: 23px;
}
.block.details button {
  height: 23px;
  outline: none;
  border: 1px solid #444;
  text-transform: uppercase;
  font-weight: 500;
}
.block.details button.reset {
  width: 25px;
  overflow: hidden;
  opacity: 0.3;
  background-image: url(images/sprites.png);
  background-repeat: no-repeat;
  background-position: -747px 2px;
  color: transparent;
}
.block.details button.active {
  background-color: var(--background-color-active);
}

@media screen and (max-width: 700px) {
  .sidebar_right {
    top: 75px;
    right: -210px;
    /* large padding with negative margin needed for absolute position with things in the overflow plus scroll ability, can't see the tooltip texts without it */
    padding-left: 420px;
    z-index: -2 !important;
  }
  .sidebar_right.active {
    /* box-shadow: -5px 0px 10px 0px rgba(0, 0, 0, 0.75); */
    z-index: 200 !important;
  }
  .sidebar_right .block {
    /* large padding with negative margin needed for absolute position with things in the overflow plus scroll ability, can't see the tooltip texts without it */
    margin-left: -200px;
  }
}

/* ========== content ======================================================= */

.main_wrapper {
  height: calc(100% - 85px);
  overflow: hidden;
  margin-left: 100px;
  margin-right: 205px; /* width of sidebar_right plus padding*/
  display: flex;
  justify-content: center;
  align-items: center;
}
.canvas_wrapper {
  position: relative;
}
.canvas_wrapper canvas {
  position: absolute;
  display: block;
  border: 1px solid var(--border-color);
  box-sizing: content-box;
}
#mouse {
  position: absolute;
  pointer-events: none;
  width: 10px;
  height: 10px;
  z-index: 10;
}
#mouse.rect {
  border: 1px solid rgba(0, 0, 0, 0.5);
}
#mouse.circle {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}
.transparent-grid {
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
  /*background: url(images/grid.png) repeat top left;*/
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAQElEQVQ4T2N89+7dfwYigKCgIBGqGBgYRw3EGU6jYYgzaIZAsvn//z9ROeX9+/fE5ZRRA3GG02gY4s4pgz7ZAAAnSWvHPkHXaAAAAABJRU5ErkJggg==')
    repeat top left;
  z-index: 1;
  /* disable antialiasing */
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
}
.transparent-grid.white {
  background: white;
}
.transparent-grid.green {
  background: #5be471;
}
.transparent-grid.grey {
  background: #dfdfdf;
}
canvas {
  position: relative;
  z-index: 2;
}
#canvas_back {
  position: absolute;
  background-color: #ffffff;
  outline: none;
}
#canvas_grid {
  pointer-events: none;
}
.group {
  border: 1px solid #999999;
  margin: 5px 0px 5px 0px;
  padding: 5px 8px;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
}
.flex-container .item {
  flex: auto;
  margin: 2px 0;
  width: 150px;
}
.flex-container .item:empty {
  height: 0;
  border: none;
}
.alertify-notifier {
  color: black;
}

.murall_button_container {
  position: absolute;
  width: 190px;
  height: 65px;
  display: inline-flex;
  justify-content: center;
  align-content: stretch;
  align-items: stretch;
  z-index: 190;
  text-align: center;
  padding: 8px;
  /* color: #e91bfa; */
  outline: 0;
  border: 1px solid var(--background-color-input-border-active);
  /* border-color: var(--border-color); */
  border-color: var(--background-color-input-border-active);
  border-radius: 35px;
  background-color: var(--background-color-input-border-active);
  cursor: pointer;
}

.murall_button_container img {
  flex: 1;
  object-fit: cover;
}

.murall_button_container span {
  flex: 2;
  align-self: center;
  font-size: 2em;
}
.murall_button_container:hover {
  background-color: rgba(255, 255, 255, 0.5);
  background-color: var(--background-color-hover);
}

.write_murall {
  background-image: url('images/upload_icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.read_murall {
  background-image: url('images/download_icon.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

@media screen and (min-width: 700px) {
  .murall_button_container {
    top: 12px;
    right: 5px;
  }
}

@media handheld and (orientation: landscape) {
  /* applies to mobiles in landscape mode */
  body.theme-dark {
    --TOP_NAV_HEIGHT: 65px;
  }
}
@media handheld and (orientation: portrait) {
  /* applies to mobiles in portrait mode */
  body.theme-dark {
    --TOP_NAV_HEIGHT: 65px;
  }
}

@media screen and (max-width: 700px) {
  body.theme-dark {
    --TOP_NAV_HEIGHT: 65px;
  }
  .sidebar_left {
    height: calc(100% - 75px);
    width: 50px;
    padding: 4px 2px 0px 2px;
    overflow-y: auto;
  }
  .attributes {
    white-space: nowrap;
    overflow-x: auto;
  }
  .sidebar_left .item {
    display: inline-block;
    width: 40px;
    margin-right: 5px;
    margin: 1px 3px 0 3px;
  }
  .main_wrapper {
    margin-right: 5px;
    margin-left: 50px;
  }
  .murall_button_container {
    width: calc(100% - 100px);
    bottom: 12px;
    right: 50px;
    left: 50px;
  }
}
@media screen and (max-width: 550px) {
  .canvas_wrapper {
    margin-left: 0px;
  }
}
.pixelated-image {
  /* Webkit color separated image looks best with pixelated, but others look best with auto. */
  image-rendering: pixelated;
  /* Opera */
  image-rendering: -o-crisp-edges;
  /* Best on Firefox and Safari, put this on bottom, webkit will ignore. */
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  /* IE */
  -ms-interpolation-mode: nearest-neighbor;
}

.murall_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -128px 0 0 -128px;
  width: 256px;
  height: 256px;
  background: url('images/murall_loading_sprites.png') left center;
  animation: play 1.5s steps(18) infinite;
}

@keyframes play {
  100% {
    background-position: -4608px;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 12px solid var(--background-color-hover);
  border-top: 12px solid var(--background-color-input-border-active);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#progressBar {
  width: 100%;
  background-color: #f3f3f3;
}

#currentProgress {
  width: 1%;
  height: 30px;
  background-color: #ed1e79;
  text-align: center; /* To center it horizontally (if you want) */
  line-height: 30px; /* To center it vertically */
  color: var(--text-color);
}

.canvascontainer {
  position: relative;
}

.canvascontainer > canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.material_input {
  font-size: 1em;
  border: 0;
  border-bottom: 1px solid #ccc;
  font-family: inherit;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  cursor: text;
  color: var(--text-color);
  background-color: var(--background-color-input);
  transition: all 0.2s;
  touch-action: manipulation;
}

.material_input:focus {
  outline: 0;
  border-bottom: 1px solid var(--background-color-input-border-active);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 180px;
  background-color: var(--background-color-active);
  color: var(--text-color);
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  /* Position the tooltip text  */
  position: absolute;
  z-index: 1;
  /* top pos*/
  bottom: 100%;
  left: 50%;
  margin-left: -40px; /* Use half of the width (200/2 = 100), to center the tooltip */
  opacity: 0;
  transition: opacity 1s;
}

.tooltip .tooltiptext::after {
  content: ' ';
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: var(--background-color-active) transparent transparent
    transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.slanted_top {
  -webkit-clip-path: polygon(0 0, 100% 48px, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 48px, 100% 100%, 0 100%);
}
.slanted {
  -webkit-clip-path: polygon(0 0, 100% 48px, 100% 95%, 0 100%);
  clip-path: polygon(0 0, 100% 48px, 100% 95%, 0 100%);
}
.slanted_reverse {
  -webkit-clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 95%);
  clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 95%);
}

.lazyWrapper {
  position: relative;
  overflow: hidden;
}

.lazySource {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in;
}
.lazyLoaded {
  opacity: 1;
}

.lqip {
  display: block;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  opacity: 1;
}

.lqipLoaded {
  -webkit-animation: fadeout 0.3s linear forwards;
  animation: fadeout 0.3s linear forwards;
  animation-delay: 0.15s;
}
