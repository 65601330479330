input[type='range'] {
  width: 100%;
  margin: 4px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  background: var(--background-color-hover);
  border: 0;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-webkit-slider-thumb {
  margin-top: -4px;
  width: 12px;
  height: 12px;
  background: var(--background-color-hover);
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #f37ffc;
}
input[type='range']::-moz-range-track {
  background: var(--background-color-hover);
  border: 0;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: var(--background-color-hover);
  border: 0;
  border-radius: 6px;
  cursor: pointer;
}
input[type='range']::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 4px 0;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-ms-fill-lower {
  background: #9e04ab;
  border: 0;
}
input[type='range']::-ms-fill-upper {
  background: var(--background-color-hover);
  border: 0;
}
input[type='range']::-ms-thumb {
  width: 12px;
  height: 12px;
  background: var(--background-color-hover);
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type='range']:focus::-ms-fill-lower {
  background: var(--background-color-hover);
}
input[type='range']:focus::-ms-fill-upper {
  background: #f37ffc;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type='range'] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

input[type='range'] {
  width: 100%;
  margin: 4px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  background: var(--background-color-hover);
  border: 0;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-webkit-slider-thumb {
  margin-top: -4px;
  width: 12px;
  height: 12px;
  background: var(--background-color-hover);
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #f37ffc;
}
input[type='range']::-moz-range-track {
  background: var(--background-color-hover);
  border: 0;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: var(--background-color-hover);
  border: 0;
  border-radius: 6px;
  cursor: pointer;
}
input[type='range']::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 4px 0;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-ms-fill-lower {
  background: #9e04ab;
  border: 0;
}
input[type='range']::-ms-fill-upper {
  background: var(--background-color-hover);
  border: 0;
}
input[type='range']::-ms-thumb {
  width: 12px;
  height: 12px;
  background: rgba(233, 27, 250, 0.93);
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type='range']:focus::-ms-fill-lower {
  background: var(--background-color-hover);
}
input[type='range']:focus::-ms-fill-upper {
  background: #f37ffc;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type='range'] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}

input[type='range'] {
  width: 100%;
  margin: 4px 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  background: var(--background-color-hover);
  border: 0;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-webkit-slider-thumb {
  margin-top: -4px;
  width: 12px;
  height: 12px;
  background: rgba(233, 27, 250, 0.93);
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #f37ffc;
}
input[type='range']::-moz-range-track {
  background: var(--background-color-hover);
  border: 0;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: var(--background-color-hover);
  border: 0;
  border-radius: 6px;
  cursor: pointer;
}
input[type='range']::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 4px 0;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
}
input[type='range']::-ms-fill-lower {
  background: #9e04ab;
  border: 0;
}
input[type='range']::-ms-fill-upper {
  background: var(--background-color-hover);
  border: 0;
}
input[type='range']::-ms-thumb {
  width: 12px;
  height: 12px;
  background: var(--background-color-hover);
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type='range']:focus::-ms-fill-lower {
  background: var(--background-color-hover);
}
input[type='range']:focus::-ms-fill-upper {
  background: #f37ffc;
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type='range'] {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
