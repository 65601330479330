* {
  box-sizing: border-box;
  /* background-repeat: no-repeat; */
  /*FireFox hide scrollbars*/
  scrollbar-width: none;
  scroll-behavior: smooth;
  /*IE10 hide scrollbars+*/
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
html {
  height: 100%;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

:root {
  /* original - default */
  --background: #000000;
  --background-blend-mode: overlay;
  --background-size: cover;
  --text-color: #fff;
  --text-color-inactive: #676767;
  --background-color-section: #000000;
  --background-color-area: #47474700;
  --background-color-header: #30303000;
  --background-color-button: #252525;
  --background-color-button-inactive: #000000;
  --background-color-dialog: #000000;
  --background-color-input: #49078300;
  --background-color-input-border-active: #7e0ae5;
  --background-color-menu: #47474700;
  --text-color-menu: #fff;
  --background-color-active: #7e0ae5;
  --background-color-hover: #e91bfa;
  --background-color-hover-button: #898989;
  --border-color: #999999;
  --TOP_NAV_HEIGHT: 65px;
  /* These control the text colour and background */
  background-color: #424f5a;
  background: var(--background);
  background-blend-mode: var(--background-blend-mode);
  color: var(--text-color);
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

body.theme-dark {
  /* original - default */
  --background: #000000;
  --background-blend-mode: overlay;
  --background-size: cover;
  --text-color: #fff;
  --text-color-inactive: #676767;
  --background-color-section: #000000;
  --background-color-area: #47474700;
  --background-color-header: #30303000;
  --background-color-button: #252525;
  --background-color-button-inactive: #000000;
  --background-color-dialog: #000000;
  --background-color-input: #49078300;
  --background-color-input-border-active: #7e0ae5;
  --background-color-menu: #47474700;
  --text-color-menu: #fff;
  --background-color-active: #7e0ae5;
  --background-color-hover: #e91bfa;
  --background-color-hover-button: #898989;
  --border-color: #999999;
  --TOP_NAV_HEIGHT: 65px;
  /* These control the text colour and background */
  background-color: #424f5a;
  background: var(--background);
  background-blend-mode: var(--background-blend-mode);
  color: var(--text-color);
  font-family: Roboto, Arial, Helvetica, sans-serif;
}
body.theme-l2 {
  /* l2 theme */
  --background: #000000;
  --background-blend-mode: overlay;
  --background-size: cover;
  --text-color: #fff;
  --text-color-inactive: #676767;
  --background-color-section: #000000;
  --background-color-area: #47474700;
  --background-color-header: #30303000;
  --background-color-button: #252525;
  --background-color-button-inactive: #000000;
  --background-color-dialog: #000000;
  --background-color-input: #49078300;
  --background-color-input-border-active: #e91bfa;
  --background-color-menu: #47474700;
  --text-color-menu: #fff;
  --background-color-active: #e91bfa;
  --background-color-hover: #1cd3cf;
  --background-color-hover-button: #898989;
  --border-color: #999999;
  --TOP_NAV_HEIGHT: 65px;
  /* These control the text colour and background */
  background-color: #424f5a;
  background: var(--background);
  background-blend-mode: var(--background-blend-mode);
  color: var(--text-color);
  font-family: Roboto, Arial, Helvetica, sans-serif;
}

.drawingContainer {
  position: fixed;
  top: var(--TOP_NAV_HEIGHT);
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0px;
  padding: 0px;
  padding-top: 10px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  color: #000000;
  color: var(--text-color);
  line-height: 1.4;
  font-weight: normal;
  overflow: hidden;
}
.drawingContainer > canvas {
  outline: none;
  /* disable select canvas */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  /* disable antialiasing */
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: pixelated;
  -ms-interpolation-mode: nearest-neighbor;
}
.drawingContainer > img {
  border: none;
}
.drawingContainer > td,
th {
  vertical-align: top;
}
.drawingContainer > table {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.drawingContainer > hr {
  border-color: rgba(0, 0, 0, 0.3);
  border-bottom: 0;
}
.drawingContainer > input[type='text'],
.drawingContainer > input[type='button'],
.drawingContainer > select,
.drawingContainer > input[type='number'],
.drawingContainer > textarea {
  border: 1px solid #393939;
  border-radius: 4px;
  border-color: var(--border-color);
  padding: 3px 5px;
  font-size: 16px;
  filter: hue-rotate(75deg);
}
.drawingContainer > input:disabled {
  opacity: 0.3;
}
.drawingContainer > select {
  padding: 2px 4px;
}
.drawingContainer > input[type='range'] {
  margin-left: 0px;
  width: 100%;
}
.drawingContainer > input[type='button'] {
  background: #252525;
}
.drawingContainer > input[type='button']:disabled {
  visibility: hidden;
}
.drawingContainer > button {
  border-radius: 3px;
  cursor: pointer;
  background-color: var(--background-color-button);
  color: var(--color-text);
}
.drawingContainer > label {
  display: inline-block;
  vertical-align: top;
  margin-top: 7px;
}
::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-track-piece {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  display: none;
}
@supports (zoom: 2) {
  input[type='radio'],
  input[type='checkbox'] {
    filter: hue-rotate(75deg);
    zoom: 1.5;
  }
}
@supports not (zoom: 2) {
  input[type='radio'],
  input[type='checkbox'] {
    transform: scale(1.5);
    filter: hue-rotate(75deg);
    margin: 8px;
  }
}
